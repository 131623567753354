/*** components ***/
import Layout from "components/common/Layout";
import Home from "components/templates/home";

/*** dataStatic ***/
import dataJson from "./data.json";

/*** ========== ***/

export default function Homes({ seo, posts }) {
  return (
    <>
      <Layout seo={seo}>
        <Home posts={posts} staticData={dataJson} />
      </Layout>
    </>
  );
}

export const getStaticProps = async () => {
  return {
    props: {
      seo: [],
      posts: [],
    },
    revalidate: 1,
  };
};
