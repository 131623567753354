import Img from "components/atoms/Img";
import Slider from "../Slider";

import styles from "./index.module.scss";

import { ErrorBoundary, withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

const StoryCard = ({ data }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={styles["story-card"]}>
        <div className={styles["card-background"]}>
          <Img
            src={data?.background?.src}
            width={data?.background?.width}
            height={data?.background?.height}
            className="h-[70vh] w-full rounded-xl object-cover lg:h-full"
            wrapper={{
              className: "w-full h-full",
            }}
          />
          <div className={styles["inner-card"]}>
            <div className={styles["card-image"]}>
              <Img
                src={data?.image?.src}
                width={data?.image?.width}
                height={data?.image?.height}
                wrapper={{
                  // style: {
                  //   width: `${data?.image?.width}px`,
                  //   height: `${data?.image?.height}px`,
                  // },
                  className: "w-[107px] h-[106px] lg:w-full lg:h-full",
                }}
              />
            </div>
            <div className={styles["card-content"]}>{data?.content}</div>
            <div className={styles["card-author"]}>
              <div
                className={styles["author-name"]}
              >{`${data?.author?.name}`}</div>
              <div className="mx-1 block lg:hidden">-</div>
              <div
                className={styles["author-country"]}
              >{`${data?.author?.country}`}</div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const Stories = ({ title, description, stories, ...props }) => {
  return (
    <>
      <div className="container">
        <h2 className="mb-6 font-Montserrat text-4xl font-bold leading-10 lg:mb-3 lg:text-center">
          {title}
        </h2>
        <p className="mb-6 lg:mb-0 lg:text-center">{description}</p>
      </div>
      <Slider
        size="full"
        className="absolute overflow-visible pb-32 lg:pb-[140px] lg:pt-10"
        inContainer
        settings={{
          dots: true,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          spaceBetween: 20,
          infinite: true,
          initialSlide: 1,
          autoPlay: true,
          speed: 5000,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: true,
                arrows: false,
                spaceBetween: 10,
              },
            },
            {
              breakpoint: 720,
              settings: {
                dots: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                spaceBetween: 5,
              },
            },
          ],
        }}
        slideProps={{
          className: "scale-90 opacity-25 transition-all duration-100 ease-out",
          activeClassName: "!scale-100 !opacity-100",
        }}
        arrowProps={{
          wrapperProps: { className: styles["story-slide-arrows"] },
          className: "bg-arrivalhub-orange-800 w-10 h-10 rounded-full p-2",
          color: "white",
        }}
      >
        {stories?.map((story, idx) => {
          return <StoryCard key={idx} data={story} />;
        })}
      </Slider>
    </>
  );
};

export default withErrorBoundary(Stories, { FallbackComponent: ErrorFallback });
