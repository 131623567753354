import { useEffect } from "react";

export const SCREEN = {
  DESKTOP: "DESKTOP",
  TABLET: "TABLET",
  MOBILE: "MOBILE",
};

export const BREAKPOINT = {
  XL: { label: "XL", minWidth: 1200 },
  LG: { label: "LG", minWidth: 1024 },
  MD: { label: "MD", minWidth: 768 },
  SM: { label: "SM", minWidth: 576 },
};

const useResizeScreenModified = (handler) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScreen = function () {
        if (window.innerWidth) {
          if (window.innerWidth <= 1023) {
            handler(SCREEN.TABLET);
          } else {
            handler(SCREEN.DESKTOP);
          }
        }
      };
      handleScreen();
      window.addEventListener("resize", handleScreen);
      return () => {
        /* Clean up function. Remove event before component unmount. */
        window.removeEventListener("resize", handleScreen);
      };
    }
  }, []);
};

export default useResizeScreenModified;
