import PropTypes from "prop-types";
import { forwardRef } from "react";
import { classNames } from "common/functions";

const Container = (
  { className, children, element: Element = "div", marginX = "mx-auto" },
  ref
) => (
  <Element ref={ref} className={classNames("container", marginX, className)}>
    {children}
  </Element>
);

// Container.propTypes = {
//   marginX: PropTypes.string,
//   className: PropTypes.string,
//   element: PropTypes.string,
//   children: PropTypes.node,
// };

export default forwardRef(Container);
