import Img from "components/atoms/Img";
import { useEffect, useState } from "react";
import Slider from "../Slider/index";

import useResizeScreenModified from "common/hooks/useResizeScreenModified";

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

const Banner = ({ background }) => {
  const [images, setImages] = useState([]);
  const [screen, setScreen] = useState(null);
  useResizeScreenModified(setScreen);

  useEffect(() => {
    if (screen === "DESKTOP") {
      setImages(background.desktop);
    } else {
      setImages(background.mobile);
    }
  }, [screen]);

  const gotoContact = (e) => {
    if (typeof document !== "undefined") {
      // will run in client's browser only
      const scrollToTable = document
        .getElementById(`Contact`)
        .getBoundingClientRect();
      window.scrollTo({
        left: 0,
        top: scrollToTable.top + window.pageYOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative h-full w-full">
      {/* <Img
        src={`/assets/images/home/banner.jpg`}
        width={4000}
        height={1417}
        className=" h-full w-full object-none "
        wrapper={{
            className: " absolute top-0 left-0 h-full w-full ",
        }}
        alt="travel"
        /> */}
      <Slider
        size="full"
        wrapperProps={{
          className: "abosolute w-full h-full inset-0",
        }}
        className="absolute inset-0 h-full w-full"
        inContainer={false}
        settings={{
          dots: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          spaceBetween: 0,
          infinite: true,
          initialSlide: 0,
          autoPlay: true,
          speed: 3000,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: false,
                arrows: false,
                spaceBetween: 0,
              },
            },
          ],
        }}
      >
        {images.map((image, index) => {
          return (
            <Img
              key={index}
              src={image.src}
              width={image.width}
              height={image.height}
              className={`h-full w-full ${
                screen === "DESKTOP" ? " object-cover" : "object-fill"
              }`}
              wrapper={{
                className: "h-full w-full",
              }}
              alt="travel-3"
            />
          );
        })}
      </Slider>
      <div className="absolute top-1/2 left-1/2 z-10 w-full -translate-x-1/2 -translate-y-1/2 transform ">
        <div className="container h-full w-full">
          <div className="lg:text-center ">
            <Img
              src={`/assets/images/home/vietnam.png`}
              width={393}
              height={105}
              wrapper={{ className: " mb-4  " }}
              className=" mx-auto hidden md:block"
              alt="Vietnam"
            />
            <Img
              src={`/assets/images/home/vietnam-mobile.png`}
              width={113}
              height={30}
              wrapper={{ className: " mb-4  md:hidden block" }}
              className=" "
              alt="Vietnam"
            />
            <h1 className="mb-6 font-Montserrat text-4xl  font-bold leading-10 text-white lg:text-center lg:text-[40px] lg:leading-[50px]">
              The Land of Culture, Wonders & History
            </h1>
            <p className="mb-6 text-lg text-white lg:mx-auto lg:mb-10 lg:col-7">
              Come to Vietnam and you’ll immerse yourself in Unique Traditions,
              Superb Food & Awe-inspiring Nature
            </p>
            <button
              onClick={(e) => gotoContact(e)}
              className="inline-block rounded bg-arrivalhub-main py-4 px-6 text-sm font-semibold text-white"
            >
              Explore now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(Banner, { FallbackComponent: ErrorFallback });
