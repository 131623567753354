// Join className String
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Replace all character string
String.prototype.replaceAll = function (search, replacement) {
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }
  let target = this;
  return target.replace(new RegExp(escapeRegExp(search), "g"), replacement);
};

export const trigger = function (eventType, data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: eventType, full_name: data.first_name });

  // const event = new CustomEvent(eventType, {
  //   detail: {
  //     dataForm: {
  //       fullname: "Contact form",
  //       email: data.email,
  //       ...data,
  //     },
  //     response: data,
  //   },
  // });
  // try {
  //   document.dispatchEvent(event);
  // } catch (error) {
  //   console.log(error);
  // }
};

/**
 * Get Google Tag Manager User ID
 */
