import { Transition } from "@headlessui/react";
import Img from "components/atoms/Img";
import useOnClickOutside from "common/hooks/useOnClickOutside";
import dataStatic from "components/common/Layout/data.json";
import { useEffect, useRef, useState } from "react";
import { MdLocalPhone } from "react-icons/md";
import styles from "./Index.module.scss";

import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/common/ErrorFallback";

/** ========== */

const Header = ({}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, setMenuOpened);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleNavbar = () => {
        const currentNav = document.getElementById("arrivalhub-header");
        if (currentNav) {
          if (
            document.body.scrollTop > 200 ||
            document.documentElement.scrollTop > 200
          ) {
            currentNav.style.boxShadow = "0 0 15px 0 rgb(119 119 119 / 30%)";
            currentNav.style.opacity = 1;
          } else {
            currentNav.style.boxShadow = "none";
            currentNav.style.opacity = 0.8;
          }
        }
      };
      handleNavbar();
      window.addEventListener("scroll", handleNavbar);

      return () => {
        window.removeEventListener("scroll", handleNavbar);
      };
    }
  }, []);
  const handleOnClickLink = (id) => {
    if (typeof document !== "undefined") {
      // will run in client's browser only
      const scrollToTable = document
        .getElementById(`${id}`)
        .getBoundingClientRect();
      window.scrollTo({
        left: 0,
        top: scrollToTable.top + window.pageYOffset - 90,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (id) => {
    handleOnClickLink(id);
    setMenuOpened(!menuOpened);
  };

  return (
    <header className="fixed top-0 z-99 w-full bg-transparent transition-all duration-75 ">
      <nav id="arrivalhub-header" className={` bg-white font-Montserrat `}>
        <div className="container">
          <div className="row h-14 items-center justify-between lg:h-20">
            {/* logo */}
            <div className="col-4 lg:col-2">
              <a
                href={"/"}
                className="inline-block cursor-pointer items-center"
              >
                <Img
                  src={`/assets/common/logo.png`}
                  className="h-full w-full"
                  height={50}
                  width={132}
                  wrapper={{
                    className: " max-w-[99px] lg:max-w-[135px] h-auto",
                  }}
                  alt="logo"
                  priority={true}
                />
              </a>
            </div>
            {/* menu ditem desktop  */}
            <div className="hidden lg:block lg:col-7 ">
              <ul className="flex items-center justify-between">
                {dataStatic?.dataMenu.map((menu, index) => {
                  return (
                    <li
                      className={styles.hover}
                      key={index}
                      onClick={() => handleClick(menu?.id)}
                    >
                      {menu?.name}
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* contact */}
            <div className="hidden lg:block lg:col-3 xl:col-2">
              <div className="flex items-center justify-center xl:justify-end">
                <MdLocalPhone size={20} className="text-black" />
                <a
                  className="ml-3 font-semibold text-black opacity-70 hover:opacity-100 lg:text-xs xl:text-sm"
                  href="tel:+65 3138 1666"
                >
                  +65 3138 1666
                </a>
              </div>
            </div>
            {/* button nav mobile */}

            <div className="col-2 block text-right lg:hidden">
              <button
                onClick={() => setMenuOpened(!menuOpened)}
                className="flex h-7 cursor-pointer flex-col justify-around border-0 bg-transparent p-0 text-right outline-none focus:outline-none"
              >
                <div
                  style={{ transformOrigin: "1px" }}
                  className={` h-0.5 w-7 rounded-sm bg-black ${
                    menuOpened
                      ? "rotate-45 transform transition-all duration-150 ease-linear "
                      : ""
                  }`}
                />
                <div
                  style={{ transformOrigin: "1px" }}
                  className={` h-0.5 w-7 rounded-sm  bg-black ${
                    menuOpened
                      ? "translate-x-2  transform opacity-0 transition-all duration-150 ease-linear "
                      : ""
                  }`}
                />
                <div
                  style={{ transformOrigin: "1px" }}
                  className={`  h-0.5 w-7 rounded-sm bg-black  ${
                    menuOpened
                      ? "-rotate-45 transform transition-all duration-150 ease-linear"
                      : ""
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="block bg-white opacity-100 lg:hidden">
          <Transition
            show={menuOpened}
            className="mt-6 overflow-hidden"
            enter="transition-all duration-300 ease-linear"
            enterFrom="transform max-h-0"
            enterTo="transform max-h-[90vh] opacity-100"
            leave="transition-all duration-200 ease-linear"
            leaveFrom="transform max-h-[90vh] opacity-50"
            leaveTo="transform max-h-0"
          >
            <ul className="mb-10 text-center">
              {dataStatic?.dataMenu.map((item, index) => {
                return (
                  <li
                    className=" mb-4 text-sm font-medium text-arrivalhub-gray-900"
                    key={index}
                  >
                    <span
                      className="cursor-pointer "
                      onClick={() => handleClick(item?.id)}
                    >
                      {item?.name}
                    </span>
                  </li>
                );
              })}
            </ul>
            <div className=" mb-10 block text-center lg:hidden">
              <div className="flex items-center justify-center">
                <MdLocalPhone />
                <a
                  className="ml-3 text-sm font-semibold text-arrivalhub-gray-900 opacity-70 hover:opacity-100"
                  href="tel:+65 3138 1666"
                >
                  +65 3138 1666
                </a>
              </div>
            </div>
          </Transition>
        </div>
      </nav>
    </header>
  );
};

export default withErrorBoundary(Header, { FallbackComponent: ErrorFallback });

/** ========== */
